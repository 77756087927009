import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import firebase from "firebase/compat/app";

import Loader from "../components/Loader";
import { Columns, Column, ModalCard } from "bloomer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "bloomer/lib/layout/Container";
import { Field } from "bloomer/lib/elements/Form/Field/Field";
import { Input } from "bloomer/lib/elements/Form/Input";
import { Label } from "bloomer/lib/elements/Form/Label";
import Utils from "../Utils";
import { Dropdown } from "bloomer/lib/components/Dropdown/Dropdown";
import { DropdownTrigger } from "bloomer/lib/components/Dropdown/DropdownTrigger";
import { DropdownMenu } from "bloomer/lib/components/Dropdown/Menu/DropdownMenu";
import { DropdownContent } from "bloomer/lib/components/Dropdown/Menu/DropdownContent";
import { DropdownItem } from "bloomer/lib/components/Dropdown/Menu/DropdownItem";
import Decimal from "decimal.js";
import { Help } from "bloomer/lib/elements/Form/Help";
import DocumentType from "../enums/DocumentType";
import { TextArea } from "bloomer/lib/elements/Form/TextArea";
import { Content } from "bloomer/lib/elements/Content";
import { Modal } from "bloomer/lib/components/Modal/Modal";
import { ModalBackground } from "bloomer/lib/components/Modal/ModalBackground";
import { ModalCardHeader } from "bloomer/lib/components/Modal/Card/ModalCardHeader";
import { ModalCardTitle } from "bloomer/lib/components/Modal/Card/ModalCardTitle";
import { ModalCardBody } from "bloomer/lib/components/Modal/Card/ModalCardBody";
import { Checkbox } from "bloomer/lib/elements/Form/Checkbox";
import { isFullWidth } from "bloomer/lib/bulma";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  Heading,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";

class DGenericDoc extends Component {
  state = {
    doc: null,
    modify: false,
    ivaDropdownActive: [],
    canaleDropdownActive: [],
    nomiDropdownActive: [],
    lottiDropdownActive: [],
    multipleValues: [],
    newLotto: "",
    lottoAddIdx: "",
    filteredProducts: [],
    submitting: false,
    saving: false,
  };

  constructor(props) {
    super(props);
    this.getTotale = this.getTotale.bind(this);
    this.getPrezzoFinale = this.getPrezzoFinale.bind(this);
    this.getPrezzoIvato = this.getPrezzoIvato.bind(this);
  }

  componentWillReceiveProps(p) {
    if (!p.admin) return;
    const { elements } = p;

    const subcollection = !!elements ? p.admin.elements : p.admin.products.products;
    console.log("ZZ subcollection receiveprops", subcollection);

    this.setState({ filteredProducts: subcollection, subcollection });
  }

  async componentDidMount() {
    const { uid, table, adding, type, elements, admin } = this.props;

    const subcollection = !!elements ? admin.elements : admin.products.products;
    if (adding) {
      const doc = {
        name: "Nuovo documento",
        creatorName: firebase.auth().currentUser.displayName,
        creator: firebase.auth().currentUser.uid,
        date: new Date(),
        scontoTotale: 0,
        speseSpedizione: 0,
        type,
      };
      this.setState({
        doc,
        modify: true,
        filteredProducts: subcollection,
        subcollection,
      });
    } else {
      const docSnap = await firebase.firestore().collection(table).doc(uid).get();

      if (docSnap.exists) {
        let doc = docSnap.data();
        const products = !!elements ? doc.elements : doc.products;
        doc.products = products;

        const data = Utils.formatDate(doc.date);
        doc.dateFormatted = data;
        doc.codeNumber = Utils.getCodeNumber(doc);

        this.setState({ doc, filteredProducts: subcollection, subcollection });
      }
    }
  }

  doModify() {
    this.setState({ modify: !this.state.modify });
  }

  submit() {
    this.setState({ submitting: true });
  }

  renderSubmitModal() {
    return (
      <AlertDialog isOpen={!!this.state.submitting} onClose={() => this.setState({ submitting: false })}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confermi salvataggio?
            </AlertDialogHeader>

            <AlertDialogFooter>
              <Button onClick={() => this.setState({ submitting: false })}>Esci</Button>
              <Button colorScheme="red" onClick={() => this.confirmSave()} isLoading={this.state.saving} ml={3}>
                Salva
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  }

  async confirmSave() {
    this.setState({ saving: true });
    const { doc } = this.state;
    doc.totalPrice = parseFloat(this.getTotale().toString());
    doc.finalPrice = parseFloat(this.getPrezzoFinale().toString());
    await this.props.onSave(doc, this.state.file);
    console.log("BBB SALVATAGGIO FINITO");
    this.setState({ saving: false, submitting: false });
    this.props.onConfirmSave(doc);
  }

  handleChange(val, field) {
    var data = {};
    data[field] = val;
    this.setState({
      ...this.state,
      doc: {
        ...this.state.doc,
        ...data,
      },
    });
  }

  handleSearchChange(val, field, values, subaccessor) {
    this.handleChange(val, field);
    const searchValuesFiltered = values.filter(
      (v) => !!v[subaccessor] && String(v[subaccessor].toLowerCase()).includes(val.toLowerCase()),
    );

    console.log("AA values", searchValuesFiltered);

    const multipleValues = [];
    multipleValues[field] = true;

    const searchValues = [];
    searchValues[field] = searchValuesFiltered;
    this.setState({
      multipleValues,
      searchValues,
    });
  }

  setMultipleValue(address, accessor) {
    const { shippingAddressMulti, shippingAddress } = address;
    console.log(address);
    const { doc } = this.state;
    doc[accessor] = address;
    console.log("AA Accessor", accessor);
    if (accessor === "contact") {
      if (!!shippingAddressMulti && shippingAddressMulti.length > 0) {
        doc["shippingAddress"] = shippingAddressMulti[0];
        doc["shippingMulti"] = shippingAddressMulti.concat(shippingAddress || {});
      } else {
        delete doc["shippingAddress"];
        delete doc["shippingMulti"];
      }
    }

    const multipleValues = [];
    multipleValues[accessor] = false;
    this.setState({
      doc,
      multipleValues,
    });
  }

  checkShowCondition(field) {
    let visible = true;
    if (!!field.showCondition) {
      const { doc } = this.state;
      const f = doc[field.showCondition[0]] || "";
      visible = f.toLowerCase() === field.showCondition[1].toLowerCase();
    }

    return visible;
  }

  renderRow(field) {
    const { fields } = field;
    return (
      <Flex gap={2}>
        {fields.map((f) => {
          return this.renderField(f);
        })}
      </Flex>
    );
  }

  renderField(field) {
    if (!this.checkShowCondition(field)) return;

    switch (field.type) {
      case "row":
        return this.renderRow(field);
      case "list":
        return this.renderList(field);
      case "multiValues":
        return this.renderMultipleValues(field);
      case "multiCheckbox":
        return this.renderMultipleCheckbox(field);
      case "search":
        return this.renderSearchField(field);
      case "textField":
        return this.renderTextField(field);
      case "pdf":
        return this.renderPdf(field);
      default:
        return this.renderInputField(field);
    }
  }

  renderInputField(f) {
    const { Header, accessor, type, blocked, modifiable, style } = f;
    const p = this.state.doc;
    const warning = type === "number" && p[accessor] < 0;
    const modify = this.state.modify && !blocked;
    return (
      <div key={Header} style={style || undefined}>
        <Label>{Header}</Label>
        <Field>
          <Input
            className={
              modify || modifiable ? (warning ? "has-text-red" : "") : warning ? "has-text-red blocked" : "blocked"
            }
            name={accessor}
            type={type}
            placeholder=""
            value={p[accessor]}
            onChange={(e) => {
              this.handleChange(type === "number" ? parseFloat(e.target.value) : e.target.value, accessor);
            }}
          />
        </Field>
      </div>
    );
  }

  renderTextField(f) {
    const { Header, accessor, type, blocked } = f;

    const p = this.state.doc;
    const warning = type === "number" && p[accessor] < 0;
    const modify = this.state.modify && !blocked;
    return (
      <div key={Header}>
        <Label>{Header}</Label>
        <Field>
          <TextArea
            className={modify ? (warning ? "has-text-red" : "") : warning ? "has-text-red blocked" : "blocked"}
            name={accessor}
            type={type}
            placeholder=""
            value={p[accessor]}
            onChange={(e) => {
              this.handleChange(type === "number" ? parseFloat(e.target.value) : e.target.value, accessor);
            }}
          />
        </Field>
      </div>
    );
  }

  renderSearchField(f) {
    const { Header, accessor, subaccessor, values, type, blocked, style } = f;

    const { doc, searchValues } = this.state;
    const modify = this.state.modify && !blocked;
    const value = !!doc && !!doc[accessor] && subaccessor ? doc[accessor][subaccessor] : doc[accessor];

    return (
      <div key={Header}>
        <Label>{Header}</Label>

        <Dropdown isActive={this.state.multipleValues[accessor]}>
          <DropdownTrigger>
            <Field>
              <Input
                style={style || undefined}
                className={modify ? "" : "blocked"}
                name={accessor}
                autoComplete="off"
                type={type}
                placeholder=""
                value={value}
                onChange={(e) => {
                  this.handleSearchChange(e.target.value, accessor, values, subaccessor);
                }}
              />
            </Field>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownContent className="elements-dropdown">
              {!!searchValues &&
                !!searchValues[accessor] &&
                searchValues[accessor].map((e, idx) => {
                  const value = !!subaccessor ? e[subaccessor] : e;
                  console.log("aa e", value);

                  return (
                    <DropdownItem key={idx} className="ta-l" onClick={() => this.setMultipleValue(e, accessor)}>
                      {value}
                    </DropdownItem>
                  );
                })}
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }

  renderMultipleValues(f) {
    const { Header, accessor, subaccessor, values: _values, modifiable, dbField, style } = f;
    const { doc, modify } = this.state;
    let values = _values || doc[accessor];

    const val =
      !!doc && !!doc[dbField] && subaccessor
        ? doc[dbField][subaccessor]
        : !!doc && !!doc[accessor] && subaccessor
        ? doc[accessor][subaccessor]
        : doc[accessor];
    console.log("AA VALUES", accessor, val, doc[dbField], subaccessor);
    return (
      <div style={style}>
        <Label>{Header}</Label>

        <Dropdown isActive={this.state.multipleValues[accessor]}>
          <DropdownTrigger style={{ minWidth: "160px" }}>
            <Button
              className={modify || modifiable ? "dropdown-warehouse modify" : "dropdown-warehouse"}
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              onClick={() => {
                if (modify || modifiable) {
                  const multipleValues = [];
                  multipleValues[accessor] = !this.state.multipleValues[accessor];
                  this.setState({
                    multipleValues,
                  });
                }
              }}
            >
              {val}
              <span className="dropdown-icn">
                <FontAwesomeIcon className="icn" icon={["fa", "chevron-down"]} />
              </span>
            </Button>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownContent className="elements-dropdown">
              {!!values &&
                values.map((e, idx) => {
                  const value = !!subaccessor ? e[subaccessor] : e;

                  return (
                    <DropdownItem
                      key={idx}
                      className="ta-l"
                      onClick={() => this.setMultipleValue(e, dbField || accessor)}
                    >
                      {value}
                    </DropdownItem>
                  );
                })}
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }

  setCheckbox(accessor, value, checked) {
    const { doc } = this.state;

    let list = doc[accessor] || [];
    if (checked) list = list.filter((v) => v != value);
    else list.push(value);

    doc[accessor] = list;

    this.setState({
      doc,
    });
  }

  getCheckbox(accessor, value) {
    const { doc } = this.state;

    const list = doc[accessor] || [];
    let found = false;
    if (Array.isArray(list)) {
      list.forEach((v) => {
        if (v === value) found = true;
      });
    } else {
      found = list === value;
    }

    return found;
  }

  renderMultipleCheckbox(f) {
    const { Header, accessor, subaccessor, values } = f;

    const { modify } = this.state;

    return (
      <div>
        <Label>{Header}</Label>
        <div className={modify ? "box" : "box blocked"}>
          {!!values &&
            values.map((e, idx) => {
              const value = !!subaccessor ? e[subaccessor] : e;
              const checked = this.getCheckbox(accessor, value);

              return (
                <Checkbox
                  key={idx}
                  style={{ marginRight: "5px" }}
                  onClick={() => this.setCheckbox(accessor, value, checked)}
                  checked={checked}
                >
                  <Label style={{ display: "inline-flex", marginRight: "45px" }} className="ta-l">
                    {value}
                  </Label>
                </Checkbox>
              );
            })}
        </div>
      </div>
    );
  }

  removePDF() {
    const { doc } = this.state;
    delete doc.pdfFilename;
    this.setState({ doc });
  }

  renderPdf(f) {
    console.log("F", f);
    return (
      <Content>
        {this.state.doc.pdfFilename ? (
          <div>
            Hai selezionato: "{this.state.doc.pdfFilename}"
            <span className="ml-6p">
              <FontAwesomeIcon onClick={(e) => this.removePDF()} icon={["fas", ["times-circle"]]} />
            </span>
          </div>
        ) : (
          <label className="button filewrapper is-fullwidth" htmlFor="contractUpload">
            <input
              type="file"
              id="contractUpload"
              name="contractUpload"
              accept=".pdf"
              onChange={(e) => this.fileChange(e)}
            />
            CARICA PDF
          </label>
        )}
      </Content>
    );
  }

  fileChange(event) {
    let c = document.getElementById("contractUpload");
    const file = c.files ? c.files[0] : null;
    const uploadDate = new Date();
    const pdfFilename = `${file.name}-${uploadDate.toISOString()}`;
    const { doc } = this.state;
    doc.pdfFilename = pdfFilename;
    this.setState({ doc, file });
  }

  renderFields() {
    const p = this.state.doc;
    const { fields } = this.props;
    return (
      <form name="doc" onChange={() => this.resetForm}>
        {fields.map((f, idx) => {
          return <div key={idx}>{this.renderField(f)}</div>;
        })}
      </form>
    );
  }

  renderProducts() {
    const { modify } = this.state;
    const { doc } = this.state;
    const preventivo = doc.type === "preventivo";
    const distinta = doc.type === "distinta";
    console.log("AA doc", doc);

    const canaleVendita = ["Sito", "Altri marketplace", "Affiliati", "Partner", "Rivenditori", "Istituzionali"];

    return (
      <div className="mtb-80">
        <h3 className="title has-text-black ">Prodotti </h3>
        <Columns>
          <Column isSize="1/4">
            <Label>Prodotto</Label>
          </Column>
          <Column style={{ width: "150px", maxWidth: "150px", minWidth: "150px" }}>
            <Label>Lotto</Label>
          </Column>
          <Column style={{ width: "150px", maxWidth: "150px" }}>
            <Label>Quantità</Label>
          </Column>

          {/* {distinta && (
            <Column style={{ width: "250px", maxWidth: "250px" }}>
              <Label>Canale vendita</Label>
            </Column>
          )} */}

          {/* {distinta && (
            <Column style={{ width: "150px", maxWidth: "150px" }}>
              <Label>Coupon</Label>
            </Column>
          )} */}

          {/* {preventivo && (
            <Column>
              <Label>Etichetta</Label>
            </Column>
          )} */}
          {preventivo && (
            <Column>
              <Label>Prezzo Unit</Label>
            </Column>
          )}
          {preventivo && (
            <Column>
              <Label>Prezzo Tot</Label>
            </Column>
          )}
          {preventivo && (
            <Column>
              <Label>IVA</Label>
            </Column>
          )}
          {preventivo && (
            <Column>
              <Label>Prezzo Ivato</Label>
            </Column>
          )}
          {preventivo && (
            <Column>
              <Label>Sconto %</Label>
            </Column>
          )}
          {preventivo && (
            <Column>
              <Label>Prezzo Finale</Label>
            </Column>
          )}
          {modify && <Column style={{ width: "60px", maxWidth: "60px" }} />}
        </Columns>
        {!!doc.products &&
          doc.products.map((s, idx) => {
            return (
              <Box
                key={idx}
                bg="gray.100"
                borderColor={"gray.300"}
                padding={"10px"}
                borderWidth={1}
                borderRadius={5}
                mb={2}
              >
                <Columns>
                  <Column isSize="1/4">
                    <Field>
                      {!!s.nomiAlternativi && s.nomiAlternativi.length > 0 ? (
                        this.renderNomi(s)
                      ) : (
                        <Input className={"blocked"} type="text" value={s.name} readOnly />
                      )}
                    </Field>
                  </Column>
                  <Column
                    style={{
                      width: "150px",
                      maxWidth: "150px",
                      minWidth: "150px",
                    }}
                  >
                    {!s.combo && (
                      <>
                        <Field>{this.renderLotti(s, idx)}</Field>
                        <Help>Disponibili {s.lottoAvailability}</Help>
                      </>
                    )}
                  </Column>
                  <Column style={{ width: "150px", maxWidth: "150px" }}>
                    <Field>
                      <Input
                        className={modify ? "" : "blocked"}
                        name="quantity"
                        type="number"
                        placeholder=""
                        value={s.quantity}
                        onChange={(e) => {
                          this.handleQuantityChange(s, parseFloat(e.target.value), idx);
                        }}
                      />
                      <Help>Disponibili {s.quantityAvailable}</Help>
                    </Field>
                  </Column>

                  {/* {distinta && (
                    <Column style={{ width: "250px", maxWidth: "250px" }}>
                      <Dropdown
                        isActive={
                          this.state.canaleDropdownActive[idx] && modify
                        }
                        style={{ width: "100%" }}
                      >
                        <DropdownTrigger style={{ width: "100%" }}>
                          <Button
                            className="dropdown-warehouse"
                            aria-haspopup="true"
                            aria-controls="dropdown-menu"
                            onClick={() => {
                              if (modify) {
                                const canaleDropdownActive = [];
                                canaleDropdownActive[idx] = !this.state
                                  .canaleDropdownActive[idx];

                                this.setState({
                                  canaleDropdownActive
                                });
                              }
                            }}
                          >
                            {s.canaleVendita}
                            <span className="dropdown-icn">
                              <FontAwesomeIcon
                                className="icn"
                                icon={["fa", "chevron-down"]}
                              />
                            </span>
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu>
                          <DropdownContent className="elements-dropdown">
                            {canaleVendita.map(v => {
                              return (
                                <DropdownItem
                                  className="ta-l"
                                  onClick={() => this.onSelectCanale(v, idx)}
                                  key={idx}
                                >
                                  {v}
                                </DropdownItem>
                              );
                            })}
                          </DropdownContent>
                        </DropdownMenu>
                      </Dropdown>
                    </Column>
                  )} */}

                  {/* {distinta && (
                    <Column style={{ width: "150px", maxWidth: "150px" }}>
                      <Field>
                        <Input
                          className={modify ? "" : "blocked"}
                          name="coupon"
                          type="text"
                          placeholder=""
                          value={s.coupon}
                          onChange={e => {
                            this.handleCouponChange(s, e.target.value, idx);
                          }}
                        />
                      </Field>
                    </Column>
                  )} */}

                  {/* {preventivo && (
                    <Column>
                      <Field>
                        <Input
                          className={modify ? "" : "blocked"}
                          type="number"
                          value={s.modEtichetta}
                          onChange={e => {
                            this.handleEtichettaChange(
                              s,
                              parseInt(e.target.value),
                              idx
                            );
                          }}
                        />
                        <Help>Mod.Etichetta</Help>
                      </Field>
                    </Column>
                  )} */}
                  {preventivo && (
                    <Column>
                      <Field>
                        <Input
                          className={preventivo ? "" : "blocked"}
                          type="text"
                          value={s.price_sell}
                          onChange={(e) => {
                            this.handlePrezzoChange(s, e.target.value, idx);
                          }}
                        />
                        <Help>Pr.Unitario {s.price_sell}</Help>
                      </Field>
                    </Column>
                  )}
                  {preventivo && (
                    <Column>
                      <Field>
                        <Input className={"blocked"} type="text" value={(s.price_sell * s.quantity).toFixed(2)} />
                        <Help>Pr.Unitario {s.price_sell}</Help>
                      </Field>
                    </Column>
                  )}
                  {preventivo && (
                    <Column>
                      <Dropdown isActive={this.state.ivaDropdownActive[idx] && modify}>
                        <DropdownTrigger>
                          <Button
                            className="dropdown-warehouse"
                            aria-haspopup="true"
                            aria-controls="dropdown-menu"
                            onClick={() => {
                              if (modify) {
                                const ivaDropdownActive = [];
                                ivaDropdownActive[idx] = !this.state.ivaDropdownActive[idx];

                                this.setState({
                                  ivaDropdownActive,
                                });
                              }
                            }}
                          >
                            {s.ivaDisplay}
                            <span className="dropdown-icn">
                              <FontAwesomeIcon className="icn" icon={["fa", "chevron-down"]} />
                            </span>
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu>
                          <DropdownContent className="elements-dropdown">
                            {this.props.admin.iva.map((e, idx2) => {
                              return (
                                <DropdownItem className="ta-l" onClick={() => this.onSelectIva(e, s, idx)} key={idx2}>
                                  {e.name}
                                </DropdownItem>
                              );
                            })}
                          </DropdownContent>
                        </DropdownMenu>
                      </Dropdown>
                    </Column>
                  )}
                  {preventivo && (
                    <Column>
                      <Field>
                        <Input className={"blocked"} type="text" value={this.getPrezzoIvato(s)} />
                      </Field>
                    </Column>
                  )}
                  {preventivo && (
                    <Column>
                      <Field>
                        <Input
                          className={modify ? "" : "blocked"}
                          name="sconto"
                          type="number"
                          placeholder=""
                          value={s.sconto}
                          onChange={(e) => {
                            this.handleScontoChange(s, parseFloat(e.target.value), idx);
                          }}
                        />{" "}
                      </Field>
                    </Column>
                  )}
                  {preventivo && (
                    <Column>
                      <Field>
                        <Input className={"blocked"} type="text" value={this.getPrezzoScontato(s)} />
                      </Field>
                    </Column>
                  )}

                  {modify && (
                    <Column style={{ width: "60px", maxWidth: "60px" }}>
                      <Field>
                        <Button onClick={() => this.onDelete(s, idx)}>
                          <FontAwesomeIcon className="icn" icon={["fa", "trash"]} />
                        </Button>
                      </Field>
                    </Column>
                  )}
                </Columns>

                {s.combo &&
                  s.prodottiFigli.map((c, kdx) => {
                    return (
                      <Box key={kdx} bg="gray.200" paddingLeft="10px" mb={4}>
                        <Columns>
                          <Column isSize="1/4">
                            <Heading fontSize={"14px"}>{c.name}</Heading>
                          </Column>
                          <Column
                            style={{
                              width: "150px",
                              maxWidth: "150px",
                              minWidth: "150px",
                            }}
                          >
                            <Field>{this.renderLotti(c, idx, kdx)}</Field>
                            <Heading fontSize="10px" mt={-2}>
                              Disponibili {c.lottoAvailability}
                            </Heading>
                          </Column>
                        </Columns>
                      </Box>
                    );
                  })}
              </Box>
            );
          })}

        {modify && (
          <div>
            <Columns>
              <Column>
                <Dropdown isActive={this.state.productDropdownActive && modify} className="dropdown-menu-bis">
                  <Field className="mb-10">
                    <Input
                      name="product"
                      autoComplete="off"
                      type="text"
                      placeholder="cerca prodotto"
                      value={this.state.searchCode}
                      onChange={(e) => {
                        this.handleSearchCode(e.target.value);
                      }}
                    />
                  </Field>
                  {/* </Column>
              <Column>
                <Dropdown isActive={this.state.productDropdownActive && modify}>
                  <DropdownTrigger>
                    <Button
                      className="is-primary"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu"
                      onClick={() => {
                        if (modify) {
                          this.setState({
                            productDropdownActive: !this.state.productDropdownActive
                          });
                        }
                      }}
                    >
                      <span>Aggiungi prodotto</span>
                    </Button>
                  </DropdownTrigger> */}
                  <DropdownMenu>
                    <DropdownContent className="elements-dropdown">
                      {this.state.filteredProducts.map((e) => {
                        return (
                          <DropdownItem className="ta-l" onClick={() => this.onSelect(e)} key={e.uid}>
                            {e.name}
                            <FontAwesomeIcon className="ml-15" icon={["fa", "plus-circle"]} />
                          </DropdownItem>
                        );
                      })}
                    </DropdownContent>
                  </DropdownMenu>
                </Dropdown>
              </Column>
            </Columns>
          </div>
        )}
      </div>
    );
  }

  getPrezzoIvato(p) {
    return ((p.price_sell * p.quantity * (100 + p.iva)) / 100).toFixed(2);
  }

  getPrezzoSoloIva(p) {
    return ((((p.price_sell * (100 - p.sconto)) / 100) * p.quantity * p.iva) / 100).toFixed(2);
  }

  getPrezzoScontato(p) {
    return ((this.getPrezzoIvato(p) * (100 - p.sconto)) / 100).toFixed(2);
  }

  getTotale() {
    const { doc } = this.state;
    let totale = new Decimal(0);
    {
      doc.products &&
        doc.products.forEach((p) => {
          totale = totale.add(this.getPrezzoScontato(p));
          console.log("AA pricetot", totale);
        });
    }
    return totale;
  }

  getTotaleIva() {
    const { doc } = this.state;
    let totaleIva = new Decimal(0);
    {
      doc.products &&
        doc.products.forEach((p) => {
          totaleIva = totaleIva.add(this.getPrezzoSoloIva(p));
          console.log("AA totaleIva", totaleIva);
        });
    }
    return totaleIva;
  }

  getPrezzoFinale() {
    const { doc } = this.state;
    const totale = this.getTotale();
    const scontoTotale = doc.scontoTotale || 0;
    const speseSpedizione = doc.speseSpedizione || 0;

    let totaleFinale = new Decimal(totale).minus(scontoTotale).add(speseSpedizione);

    return totaleFinale;
  }

  renderNomi(p) {
    const { modify } = this.state;

    return (
      <div>
        <Button
          className="dropdown-warehouse button-text-dropdown"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={() => {
            if (modify) {
              const nomiDropdownActive = [];
              nomiDropdownActive[p.uid] = !this.state.nomiDropdownActive[p.uid];

              this.setState({
                nomiDropdownActive,
              });
            }
          }}
        >
          {p.nomeDisplay}
          <span className="dropdown-icn">
            <FontAwesomeIcon className="icn" icon={["fa", "chevron-down"]} />
          </span>
        </Button>
        <Dropdown isActive={this.state.nomiDropdownActive[p.uid] && modify}>
          <DropdownTrigger />
          <DropdownMenu>
            <DropdownContent className="elements-dropdown">
              {p.nomiAlternativi.map((e) => {
                return (
                  <DropdownItem className="ta-l" onClick={() => this.onSelectNome(e, p)} key={e}>
                    {e}
                  </DropdownItem>
                );
              })}
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }

  onSelectNome(e, product) {
    let { doc } = this.state;
    const products = doc.products;
    products.forEach((p) => {
      if (p.uid === product.uid) {
        p.nomeDisplay = e;
      }
    });

    doc.products = products;

    const nomiDropdownActive = [];
    nomiDropdownActive[product.uid] = false;

    this.setState({
      doc,
      nomiDropdownActive,
    });
  }

  renderLotti(p, idx, kdx = null) {
    const { modify } = this.state;
    const lotti = !!p.lotti ? p.lotti.slice() : [];

    const notAvailable = {
      name: "N/D",
    };

    lotti.unshift(notAvailable);
    const nuovoLotto = {
      name: "Nuovo",
    };
    lotti.push(nuovoLotto);
    return (
      <Box bg="gray.200">
        <Button
          style={{ overflow: "hidden", justifyContent: "flex-end" }}
          className="dropdown-warehouse button-text-dropdown"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={() => {
            if (modify) {
              const lottiDropdownActive = [];
              lottiDropdownActive[idx * 100 + kdx] = !this.state.lottiDropdownActive[idx * 100 + kdx];

              this.setState({
                lottiDropdownActive,
              });
            }
          }}
        >
          {p.lotto}
          <span className="dropdown-icn">
            <FontAwesomeIcon className="icn" icon={["fa", "chevron-down"]} />
          </span>
        </Button>
        <Dropdown isActive={this.state.lottiDropdownActive[idx * 100 + kdx] && modify}>
          <DropdownTrigger />
          <DropdownMenu>
            <DropdownContent className="elements-dropdown">
              {lotti.map((e, idx2) => {
                return (
                  <DropdownItem className="ta-l" onClick={() => this.onSelectLotto(e, p, idx, kdx)} key={idx2}>
                    {e.name}
                  </DropdownItem>
                );
              })}
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>

        {lotti && (
          <Modal className=" has-text-centered is-small" isActive={this.state.lottoModalActive}>
            <ModalBackground onClick={() => this.setState({ modalActive: true })} />
            <ModalCard>
              <ModalCardHeader className="no-pb">
                <ModalCardTitle className="has-text-centered no-pl mb-10">Lotti</ModalCardTitle>
              </ModalCardHeader>
              <ModalCardBody>
                {/* {!!p.lotti &&
                  p.lotti.map((n, index) => ( */}
                <Columns>
                  <Column>
                    <Field className="mb-10">
                      <Input
                        name="newLotto"
                        placeholder=""
                        value={this.state.newLotto}
                        onChange={(e) => {
                          this.setState({
                            newLotto: e.target.value,
                          });
                        }}
                      />
                    </Field>
                  </Column>
                </Columns>
                {/* ))} */}
                {/* <Button onClick={() => this.addLottoToList(p)}>Nuovo</Button> */}
              </ModalCardBody>
              <div className="has-text-centered">
                <Columns>
                  <Column>
                    <Button
                      className="is-primary is-pulled-right"
                      onClick={() => this.setState({ lottoModalActive: false })}
                    >
                      CANCEL
                    </Button>
                  </Column>
                  <Column>
                    <Button
                      className="is-primary is-pulled-right"
                      onClick={() => {
                        this.addLottoToDatabase(p, idx);
                      }}
                    >
                      Ok
                    </Button>
                  </Column>
                </Columns>
              </div>
            </ModalCard>
          </Modal>
        )}
      </Box>
    );
  }

  addLottoToList(product) {
    let { doc } = this.state;

    let list = [];
    if (!!product.lotti) {
      list = product.lotti;
    }
    list.push({ name: "", quantity: 0 });

    doc.products.forEach((p) => {
      if (p.uid === product.uid) {
        p.lotti = list;
      }
    });

    this.setState({
      doc,
    });
  }

  async addLottoToDatabase(product, idx) {
    let { doc, lottoAddIdx } = this.state;
    const p = doc.products[lottoAddIdx];

    if (!p.lotti) p.lotti = [];
    p.lotti.push({ name: this.state.newLotto, quantity: 0 });

    this.setState({ lottoModalActive: false, newLotto: "" });

    firebase
      .firestore()
      .collection("products")
      .doc(p.uid)
      .update({ lotti: p.lotti.filter((l) => l.name != "Nuovo") });
  }

  onSelectLotto(e, product, idx, kdx) {
    if (e.name == "Nuovo") {
      this.setState({ lottoModalActive: true, lottoAddIdx: idx });
      return;
    }

    let { doc } = this.state;
    const p = doc.products[idx];

    if (kdx != null) {
      console.log("AA doc prod kdx ", p.prodottiFigli[kdx], doc.products, product, p);

      p.prodottiFigli[kdx].lotto = e.name;
      p.prodottiFigli[kdx].lottoAvailability = e.quantity || 0;
    } else {
      p.lotto = e.name;
      p.lottoAvailability = e.quantity || 0;
    }

    const lottiDropdownActive = [];
    lottiDropdownActive[idx * 100 + kdx] = false;
    console.log("AA doc prod, ", doc.products, product, p);

    this.setState({
      doc,
      lottiDropdownActive,
    });
  }

  renderTotale() {
    const totale = this.getTotale();
    const { modify, doc } = this.state;
    return (
      <div>
        <hr style={{ border: "1px solid black" }} />
        <Columns>
          <Column>
            <h3 className="subtitle has-text-black ">Imponibile </h3>
          </Column>
          <Column>
            <h3 className="subtitle has-text-black float-right">{Utils.stringToCurrency(totale)}</h3>
          </Column>
        </Columns>
        {doc.type === DocumentType.PREVENTIVO && (
          <Columns>
            <Column>
              <h3 className="subtitle has-text-black ">Di cui IVA </h3>
            </Column>
            <Column>
              <h3 className="subtitle has-text-black float-right">{Utils.stringToCurrency(this.getTotaleIva())}</h3>
            </Column>
          </Columns>
        )}
        <Columns>
          <Column>
            <h3 className="subtitle has-text-black ">Sconto o arrotondamento</h3>
          </Column>
          <Column>
            {modify ? (
              <Field className="float-right">
                <Input
                  className={modify ? "" : "blocked"}
                  name="scontoTotale"
                  type="number"
                  placeholder="0,00"
                  value={doc.scontoTotale}
                  style={{ textAlign: "right", width: "100px" }}
                  onChange={(e) => {
                    this.handleScontoFinaleChange(parseFloat(e.target.value));
                  }}
                />
              </Field>
            ) : (
              <h3 className="subtitle has-text-black float-right">{Utils.stringToCurrency(doc.scontoTotale)} </h3>
            )}
          </Column>
        </Columns>
        <Columns>
          <Column>
            <h3 className="subtitle has-text-black ">Spese Spedizione (iva inclusa)</h3>
          </Column>
          <Column>
            {modify ? (
              <Field className="float-right">
                <Input
                  className={modify ? "" : "blocked"}
                  name="speseSpedizione"
                  type="number"
                  placeholder={0}
                  value={doc.speseSpedizione}
                  style={{ textAlign: "right", width: "100px" }}
                  onChange={(e) => {
                    this.handleSpeseSpedizioneChange(parseFloat(e.target.value));
                  }}
                />
              </Field>
            ) : (
              <h3 className="subtitle has-text-black float-right">{Utils.stringToCurrency(doc.speseSpedizione)} </h3>
            )}
          </Column>
        </Columns>
        {doc.type === DocumentType.PREVENTIVO && (
          <Columns>
            <Column>
              <h3 className="subtitle has-text-black ">Costo Spedizione</h3>
            </Column>
            <Column>
              {modify ? (
                <Field className="float-right">
                  <Input
                    className={modify ? "" : "blocked"}
                    name="costoSpedizione"
                    type="number"
                    placeholder={0}
                    value={doc.costoSpedizione}
                    style={{ textAlign: "right", width: "100px" }}
                    onChange={(e) => {
                      this.handleCostoSpedizioneChange(parseFloat(e.target.value));
                    }}
                  />
                </Field>
              ) : (
                <h3 className="subtitle has-text-black float-right">{Utils.stringToCurrency(doc.costoSpedizione)} </h3>
              )}
            </Column>
          </Columns>
        )}
        <Columns>
          <Column>
            <h3 className="title has-text-black ">Totale </h3>
          </Column>
          <Column>
            <h3 className="title has-text-black float-right">{Utils.stringToCurrency(this.getPrezzoFinale())}</h3>
          </Column>
        </Columns>
      </div>
    );
  }

  handleScontoFinaleChange(val) {
    let { doc } = this.state;

    doc.scontoTotale = val;
    this.setState({ doc });
  }

  handleSpeseSpedizioneChange(val) {
    let { doc } = this.state;

    doc.speseSpedizione = val;
    this.setState({ doc });
  }

  handleCostoSpedizioneChange(val) {
    let { doc } = this.state;

    doc.costoSpedizione = val;
    this.setState({ doc });
  }

  handleCouponChange(s, val, idx) {
    let { doc } = this.state;
    const p = doc.products[idx];

    p.coupon = val;

    this.setState({ doc });
  }

  handleQuantityChange(s, val, idx) {
    let { doc } = this.state;
    const p = doc.products[idx];

    p.quantity = val;

    this.setState({ doc });
  }

  handleEtichettaChange(s, val, idx) {
    let { doc } = this.state;
    const p = doc.products[idx];

    p.modEtichetta = val;

    this.setState({ doc });
  }

  handleScontoChange(s, val, idx) {
    let { doc } = this.state;
    const p = doc.products[idx];

    p.sconto = val;

    this.setState({ doc });
  }

  handlePrezzoChange(s, val, idx) {
    let { doc } = this.state;
    const p = doc.products[idx];
    console.log("AA prezzo change", val);
    p.price_sell = val;

    this.setState({ doc });
  }

  handleSearchCode(text) {
    const filteredProducts = this.state.subcollection.filter(
      (p) =>
        p.active === true &&
        (String(p.name.toLowerCase()).includes(text.toLowerCase()) ||
          (!!p.barcode && String(p.barcode.toLowerCase()).includes(text.toLowerCase())) ||
          (!!p.code && String(p.code.toLowerCase()).includes(text.toLowerCase())) ||
          (!!p.sku && String(p.sku.toLowerCase()).includes(text.toLowerCase()))),
    );

    this.setState({
      searchCode: text,
      productDropdownActive: true,
      filteredProducts,
    });
  }

  onSelect(e) {
    let { doc } = this.state;
    const nomiAlternativi = !!e.nomiAlternativi ? e.nomiAlternativi : "";
    const lotti = !!e.lotti ? e.lotti : "";
    const el = {
      name: e.name,
      brand: e.brand,
      nomeDisplay: e.name,
      combo: e.combo,
      codiciFigli: e.codiciFigli || [],
      prodottiFigli: e.prodottiFigli || [],
      nomiAlternativi: nomiAlternativi,
      lotti: lotti,
      uid: e.uid,
      price_sell: this.props.admin.products.productsTable[e.sku]?.prezzo_vendita_b2b || 0,
      price_buy: this.props.admin.products.productsTable[e.sku]?.prezzo_acquisto || 0,
      iva: e.brand === "SUPRAPET" ? 22 : 10,
      ivaDisplay: e.brand === "SUPRAPET" ? "22%" : "10%",
      quantity: 0,
      sconto: 0,
      sku: e.sku,
      quantityAvailable: e.quantity,
    };
    const products = !!doc.products ? doc.products : [];
    products.push(el);
    doc.products = products;
    this.setState({ doc, productDropdownActive: false, searchCode: "" });
  }

  onSelectIva(e, product, idx) {
    let { doc } = this.state;
    const p = doc.products[idx];
    // products.forEach(p => {
    //   if (p.uid === product.uid) {
    p.iva = e.value;
    p.ivaDisplay = e.name;
    //   }
    // });
    console.log("aa e", e, product);

    // doc.products = products;

    const ivaDropdownActive = [];
    ivaDropdownActive[idx] = false;

    this.setState({
      doc,
      ivaDropdownActive,
    });
  }

  onSelectCanale(e, idx) {
    let { doc } = this.state;
    const p = doc.products[idx];

    p.canaleVendita = e;

    const canaleDropdownActive = [];
    canaleDropdownActive[idx] = false;

    this.setState({
      doc,
      canaleDropdownActive,
    });
  }

  async onDelete(s, idx) {
    let { doc } = this.state;
    doc.products.splice(idx, 1);
    this.setState({ doc });
  }

  renderAzioni() {
    const p = this.state.doc;

    return (
      <Flex gap={2} mt={4}>
        {!this.state.modify && p.type === "preventivo" && (
          <Button colorScheme="green" onClick={() => this.doModify()}>
            MODIFICA
          </Button>
        )}

        {this.state.modify && (
          <Button colorScheme="green" onClick={() => this.submit()}>
            SALVA
          </Button>
        )}
        {this.state.modify && (
          <Button colorScheme="green" onClick={() => this.doModify()}>
            ESCI
          </Button>
        )}

        {this.props.onCreaDDT && p.type === "preventivo" && (
          <Button colorScheme="green" onClick={() => this.props.onCreaDDT(p)}>
            CREA DDT
          </Button>
        )}

        {(p.type === "distinta" || p.type === "entrata") && !this.state.modify && (
          <Button colorScheme="green" onClick={() => this.submit()}>
            Salva
          </Button>
        )}
      </Flex>
    );
  }
  render() {
    const p = this.state.doc;

    if (!p) return <Loader />;

    let title = "";
    if (p.type === DocumentType.ENTRATA) title = "Distinta d'entrata";
    else if (p.type == DocumentType.DISTINTA) title = "Distinta d'uscita";
    else if (p.type == DocumentType.PREVENTIVO) title = "Preventivo Ordini e Acquisti";
    else if (p.type == DocumentType.ENTRATA_PROD) title = "Entrata produzione";
    else if (p.type == DocumentType.USCITA_MANUALE) title = "Uscita produzione";

    if (!!p.codeNumber) title = title + " - " + p.codeNumber;
    return (
      <div>
        <Columns isMobile>
          <Column>
            <Button
              m={2}
              colorScheme="green"
              onClick={() =>
                this.props.location.goBack
                  ? this.props.history.push(this.props.location.goBack)
                  : this.props.history.goBack()
              }
              className="title user-area-title vw-tablet"
            >
              <Flex align={"center"} gap={2}>
                <FontAwesomeIcon icon={["fas", "chevron-left"]} />
                <Heading fontSize={"md"}>Torna indietro</Heading>
              </Flex>
            </Button>
          </Column>
        </Columns>

        <Container className="generic-doc">
          <h1 className="title has-text-black">{title}</h1>
          {!!p.dateFormatted && <h2 className="subtitle has-text-black">{p.dateFormatted}</h2>}
          {this.renderFields()}

          {this.renderProducts()}

          {p.type === "preventivo" && this.renderTotale()}

          {this.renderAzioni()}
        </Container>

        {this.renderSubmitModal()}
      </div>
    );
  }
}

export default withRouter(DGenericDoc);
